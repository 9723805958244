export const isEmailFormatValid = (email) => {
	const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
	const localpart = email.split('@')[0];
	const valid = email && regex.test(email) && localpart.length <= 64;

	return valid;
};

export const isFestoEmail = (email) => {
	const emailDomain = email.split('@')[1];
	if (emailDomain === "festo.com") {
		return true;
	}
	if (emailDomain === "festo.net") {
		return true;
	}
	return false;
};