import React from 'react';
import { useState, useContext } from 'react';
import './Home.css';
import Header from '../../content/header/Header';
import { ResourceManager, orgIconG, homeIconG, homeIconB, isLoggedIn, getProfileInfos, setProfileInfos, infoIconG, infoIconB, docIconG, docIconB } from '../../index';
import { useAuth } from "../../provider/authProvider";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import CookiesPolicyConsentModal from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';
import TermsOfUseConsentModal from '../../content/termsOfUse/TermsOfUseModal';
import TermsOfUseConsentContext from '../../contexts/termsOfUseConsent/withTermsOfUseConsentContext';
import NoBmcLicenseModal from '../../content/noBmcLicense/NoBmcLicenseModal';

export default function Home() {
    const [homeIconColor, setHomeIconColor] = useState(homeIconG);
    const [orgIconColor, setOrgIconColor] = useState(orgIconG);
    const [docIconColor, setDocIconColor] = useState(docIconG);
    const [infoIconColor, setInfoIconColor] = useState(infoIconG);
    const [userHasNoLicense, setUserHasNoLicense] = useState(false);
    const urlunity = process.env.REACT_APP_UNITY_ENDPOINT;
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = useContext(CookiesPolicyConsentContext);
    const { termsOfUseModalOpen, handleTermsOfUseModalClose } = useContext(TermsOfUseConsentContext);

    function homeClick(e) {
        e.preventDefault();
        var iframe = document.getElementById('iframeBmc');
        if (iframe) {
            iframe.contentWindow.postMessage('HomeButtonClick', '*');
        }
    }

    async function OnLoad(e) {
        e.preventDefault();
        await checkLog();
        var iframe = document.getElementById('iframeBmc');
        if (iframe) {            
            let infos = getProfileInfos();
            if (infos) {
                iframe.contentWindow.postMessage('token=' + infos["bmcToken"], '*');
                await ValidateLicense(infos); 
            }
        }                
    }

    async function ValidateLicense(infos) {
        let userLicenseInfo = {
            userId: infos.id,
            currentOrganizationId: infos.currentOrganizationId,
            organizationIds: infos.organizationIds,
            lastConnection: new Date(),        
        }
        const urllogin = process.env.REACT_APP_API_URL + "validateLicense";        
        axios.post(urllogin, userLicenseInfo, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
            }
        }, 
        ).then(response => {
            if (response.status === 200) {
                let organizationId = response.data;
                infos.currentOrganizationId = organizationId;

                setProfileInfos(infos);
                setUserHasNoLicense(false);
            }
        }).catch(error => {
            if (error.response.status === 401) {
                setUserHasNoLicense(true);
            }
        });        
    }

    async function checkLog() {
        let isLogged = await isLoggedIn();
        if (!isLogged) {
            localStorage.removeItem('profileInfos');
            setToken();
            navigate('/login')
        }
    }

    return (
        <div className="Home">
            <header className="Home-Header border-bottom">
                <Header/>
            </header>
            {TermsOfUseConsentModal(termsOfUseModalOpen, handleTermsOfUseModalClose)}
            {!termsOfUseModalOpen && CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
            {!termsOfUseModalOpen && !cookiesPolicyModalOpen && userHasNoLicense && <NoBmcLicenseModal showDismiss={true} infoText={ResourceManager.getValue("reducedFunctionality")}/>}
            <div className="Home-MainContent flex">
                <div className="homeSidebar border-right">
                    <div>
                        <a className="icon-top centerAlign flex" type="button" href="/home" onMouseOver={() => setHomeIconColor(homeIconB)}
                            onMouseOut={() => setHomeIconColor(homeIconG)} onClick={(e) => homeClick(e)}>
                            <img className="homeIcon" src={homeIconColor} alt="HomeIcon" />
                        </a>
                        <a className="icon-top centerAlign flex" type="button" href={"/" + ResourceManager.getLanguage() + "/organizationPage"}
                            onMouseOut={() => setOrgIconColor(orgIconG)} target="_blank" rel="noreferrer">
                            <img className="orgIcon" src={orgIconColor} alt="OrgIcon" />
                        </a>
                        <a className="icon-top centerAlign flex" href={"/" + ResourceManager.getLanguage() + "/workbook"} onMouseOver={() => setDocIconColor(docIconB)}
                            onMouseOut={() => setDocIconColor(docIconG)} target="_blank" rel="noreferrer">
                            <img className="docIcon" src={docIconColor} alt="DocIcon" />

                        </a>
                    </div>
                    <span className="sidebar-center-span" />
                    <div>
                        <a className="icon-bottom centerAlign flex" href={"/" + ResourceManager.getLanguage() + "/information"}
                            onMouseOver={() => setInfoIconColor(infoIconB)}
                            onMouseOut={() => setInfoIconColor(infoIconG)} target="_blank" rel="noreferrer">
                            <img className="orgIcon" src={infoIconColor} alt="InfoIcon" />
                        </a>
                    </div>
                </div>
                <iframe className="bmcSimApp" id="iframeBmc" src={urlunity} allow="xr-spatial-tracking" title="BMC Sim app" onLoad={(e) => OnLoad(e)} />
            </div>
        </div>  
    );
}