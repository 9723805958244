import Header from '../../content/header/Header';
import './OrganizationPage.css';
import TermsOfUseConsentModal from '../../content/termsOfUse/TermsOfUseModal';
import TermsOfUseConsentContext from '../../contexts/termsOfUseConsent/withTermsOfUseConsentContext';
import CookiesPolicyConsentModal from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';
import { ResourceManager, orgIconG, orgIconB, homeIconG, homeIconB, isLoggedIn, getProfileInfos, infoIconG, infoIconB, docIconG, docIconB } from '../../index';
import { useState, useContext } from 'react';
import NoBmcLicenseModal from '../../content/noBmcLicense/NoBmcLicenseModal';
import { useAuth } from "../../provider/authProvider";
import { useNavigate } from 'react-router-dom';

export default function OrganizationPage() {
    const { termsOfUseModalOpen, handleTermsOfUseModalClose } = useContext(TermsOfUseConsentContext);
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = useContext(CookiesPolicyConsentContext);
    const [userHasNoLicense] = useState(false);
    const [homeIconColor, setHomeIconColor] = useState(homeIconG);
    const [orgIconColor, setOrgIconColor] = useState(orgIconG);
    const [docIconColor, setDocIconColor] = useState(docIconG);
    const [infoIconColor, setInfoIconColor] = useState(infoIconG);
    const lxUrl = process.env.REACT_APP_LX_URL + ResourceManager.getLanguage() + "/my-organization/licenses?with-page-template=0&source-application=fluidsim";
    const { setToken } = useAuth();
    const navigate = useNavigate();

    async function OnLoad(e) {
        e.preventDefault();
        await checkLog();
        var iframe = document.getElementById('iframeBmc');
        if (iframe) {
            let infos = getProfileInfos();
            if (infos) {               
            }
        }
    }        

    async function checkLog() {
        let isLogged = await isLoggedIn();
        if (!isLogged) {
            localStorage.removeItem('profileInfos');
            setToken();
            navigate('/login')
        }
    }

    return (
        <div className="Home">
            <header className="Home-Header border-bottom">
                <Header />
            </header>
            {TermsOfUseConsentModal(termsOfUseModalOpen, handleTermsOfUseModalClose)}
            {!termsOfUseModalOpen && CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
            {!termsOfUseModalOpen && !cookiesPolicyModalOpen && userHasNoLicense && <NoBmcLicenseModal showDismiss={true} infoText={ResourceManager.getValue("reducedFunctionality")} />}
            <div className="Home-MainContent flex">
                <div className="homeSidebar border-right">
                    <div>
                        <a className="icon-top centerAlign flex" type="button" href={"/" + ResourceManager.getLanguage() + "/home"}
                            onMouseOver={() => setHomeIconColor(homeIconB)} onMouseOut={() => setHomeIconColor(homeIconG)}>
                            <img className="homeIcon" src={homeIconColor} alt="HomeIcon" />
                        </a>
                        <a className="icon-top centerAlign flex" type="button" href={"/" + ResourceManager.getLanguage() + "/organizationPage"}
                            onMouseOver={() => setOrgIconColor(orgIconB)} onMouseOut={() => setOrgIconColor(orgIconG)}>
                            <img className="orgIcon" src={orgIconColor} alt="OrgIcon" />
                        </a>
                        <a className="icon-top centerAlign flex" href={"/" + ResourceManager.getLanguage() + "/workbook"} onMouseOver={() => setDocIconColor(docIconB)}
                            onMouseOut={() => setDocIconColor(docIconG)} target="_blank" rel="noreferrer">
                            <img className="docIcon" src={docIconColor} alt="DocIcon" />

                        </a>
                    </div>
                    <span className="sidebar-center-span" />
                    <div>
                        <a className="icon-bottom centerAlign flex" href={"/" + ResourceManager.getLanguage() + "/information"}
                            onMouseOver={() => setInfoIconColor(infoIconB)}
                            onMouseOut={() => setInfoIconColor(infoIconG)} target="_blank" rel="noreferrer">
                            <img className="orgIcon" src={infoIconColor} alt="InfoIcon" />
                        </a>
                    </div>
                </div>                
                <iframe className="lxorgpage" id="iframeBmc" src={lxUrl} allow="xr-spatial-tracking" title="BMC Sim app" onLoad={(e) => OnLoad(e)} />               
            </div>
        </div>
    );
}