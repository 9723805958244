import React, { useEffect } from 'react';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';
import { setProfileInfos, ResourceManager } from "../../index";
import Header from '../../content/header/Header';
import Footer from '../../content/footer/Footer';
import { useAuth } from "../../provider/authProvider";
import axios from "axios";
import { useState, useContext } from "react";
import CookiesPolicyConsentModal from '../../content/cookiesPolicy/CookiesPolicyConsentModal';
import ProgressiveImg from '../../components/progressiveImage/ProgressiveImage';
import { bmcPicture4, bmcPlaceholder4 } from '../../index';
import ResetPasswordContext from '../../contexts/resetPassword/withResetPasswordContext';
import ResetPasswordModal from './ResetPasswordModal';
import CookiesPolicyConsentContext from '../../contexts/cookiesPolicyConsent/withCookiesPolicyConsentContext';
import { isFestoEmail } from '../../utility/emailValidationUtils';
import { OpenIdUtils } from '../../utility/openIdUtils';
import { UrlUtils } from '../../utility/urlUtils';

export default function LoginPage() {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const [loginError, setLoginError] = useState("");
    const [authenticating, setAuthenticating] = useState(false);
    const [resetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
    const [ssoAvailable, setSsoAvailable] = useState(false);
    const { cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose } = useContext(CookiesPolicyConsentContext);
    const [usernameInputEmpty, setUsernameInputEmpty] = useState(true);
    const [passwordInputEmpty, setPasswordInputEmpty] = useState(true);

    useEffect(() => {
        const ssoLogin = async () => {
            if (UrlUtils.queryParamExists(window.location.href, "code")) {
                setAuthenticating(true);
                let authenticationCode = UrlUtils.getQueryParam(window.location.href, "code");
                let ssoRequest = {
                    clientId: OpenIdUtils.getClientId(),
                    authenticationCode: authenticationCode,
                    codeVerifier: OpenIdUtils.getCodeVerifier(),
                    organizationUserInvitationHash: "",
                    organizationPublicInvitationLinkHash: ""
                };
                OpenIdUtils.clearSsoInfos();

                if (ssoRequest.clientId && ssoRequest.codeVerifier) {

                    // Cookie policy                               
                    const cookiesUrl = process.env.REACT_APP_LX_API_URL + "api/cookies-policy/accept";
                    var cookiesAcceptationLevel = {
                        AcceptedCookieLevel: 1
                    };
                    try {
                        await axios.post(cookiesUrl, cookiesAcceptationLevel, {
                            withCredentials: true,
                            headers: {
                                "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                            },
                        })
                    }
                    catch (error) {
                        console.log(error);
                        setAuthenticating(false);
                        setLoginError(ResourceManager.getValue("loginFailed"));
                    }

                    // Login LX SSO
                    var responseLX;
                    const lxUrl = process.env.REACT_APP_LX_API_URL + "api/users/bmcsim-openid-login";
                    try {
                        responseLX = await axios.post(lxUrl, ssoRequest, {
                            withCredentials: true,
                            headers: {
                                "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", "Request-Language": ResourceManager.getLanguage(),
                            },
                        })
                    }
                    catch (error) {
                        console.log(error);
                        setAuthenticating(false);
                        setLoginError(ResourceManager.getValue("loginFailed"));
                    }
                    if (responseLX) {                        
                        let infoUser = responseLX.data;                     
                        var userProfile = await getUserProfile(infoUser.loginInformation.user.id);
                        var userOrganizations = await getUserOrganizations(infoUser.loginInformation.user.id);
                        var userThumbnail = await getUserThumnail(infoUser.loginInformation.user.id);                        

                        let infosLxWithTokens = {
                            firstName: infoUser.loginInformation.user.firstName,
                            lastName: infoUser.loginInformation.user.lastName,
                            id: infoUser.loginInformation.user.id,
                            email: userProfile.email,
                            organizationIds: userOrganizations,
                            currentOrganizationId: userProfile.organizationId,
                            bmcToken: "",
                            profilePictureThumbnailJpegBase64: userThumbnail
                        }
                        loginBmc(infosLxWithTokens);

                    }
                }
            }

            if (UrlUtils.queryParamExists(window.location.href, "error")) // erreur SSO retourn�e par microsoft
            {
                console.log(UrlUtils.getQueryParam(window.location.href, "error_description"));
                setAuthenticating(false);
                OpenIdUtils.clearSsoInfos();
                window.location.href = UrlUtils.getUrlWithoutQueryParams(window.location.href) + "?sso-error=true";
            }

            if (UrlUtils.queryParamExists(window.location.href, "sso-error")) {
                setLoginError(ResourceManager.getValue("loginSsoFailed"));
            }
        }
        ssoLogin();
    }, [setAuthenticating, setLoginError, setToken, navigate]);

    async function getUserProfile(userId) {
        var response;
        const lxUrl = process.env.REACT_APP_LX_API_URL + "api/users/" + userId + "/profile";
        try {
            response = await axios.get(lxUrl, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", "Request-Language": ResourceManager.getLanguage(),
                },
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            setLoginError(ResourceManager.getValue("loginFailed"));
        }
        if (response) {
            return (response.data);
        }
        return "";
    }

    async function getUserOrganizations(userId) {
        var response;
        const lxUrl = process.env.REACT_APP_LX_API_URL + "api/users/" + userId + "/organizations";
        try {
            response = await axios.get(lxUrl, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", "Request-Language": ResourceManager.getLanguage(),
                },
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            setLoginError(ResourceManager.getValue("loginFailed"));
        }
        if (response) {
            var organizationIds = [];
            let organizations = response.data;
            organizations.forEach(organization => {
                const organizationId = parseInt(organization.id, 10);
                organizationIds.push(organizationId);
            })
            return (organizationIds);
        }
        return "";
    }

    async function getUserThumnail(userId) {
        var response;
        const lxUrl = process.env.REACT_APP_LX_API_URL + "api/users/" + userId + "/image/thumbnail";
        try {
            response = await axios.get(lxUrl, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*", "Request-Language": ResourceManager.getLanguage(),
                },
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            setLoginError(ResourceManager.getValue("loginFailed"));
        }
        if (response) { 
            var startIndex = response.data.indexOf(',') + 1;
            var profilePictureThumbnailJpegBase64 = response.data.substring(startIndex);
            return (profilePictureThumbnailJpegBase64);
        }
        return "";
    }

    async function loginBmc(infosLxWithTokens) {
        var response;        
        let infos = {
            CurrentOrganizationId: infosLxWithTokens.currentOrganizationId,
            OrganizationIds: infosLxWithTokens.organizationIds,            
        }
        response = await UpdateLicense(infos);

        const url = process.env.REACT_APP_API_URL + "loginBmc";
        try {
            response  = await axios.post(url, infosLxWithTokens, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    "Request-Language": ResourceManager.getLanguage(),
                }
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            OpenIdUtils.clearSsoInfos();
            window.location.href = UrlUtils.getUrlWithoutQueryParams(window.location.href) + "?sso-error=true";
        }

        if (response) {
            setAuthenticating(false);
            let infos = response.data;
            setProfileInfos(infos);
            let bmcToken = infos["bmcToken"];
            setToken(bmcToken);
            navigate("../home");
        }        
    }


    async function submitLogIn() {
        setAuthenticating(true);
        const formElement = document.getElementById("logInForm");
        const formData = new FormData(formElement);

        let loginUserCredentials = {
            username: formData.get("username"),
            password: formData.get("password"),
            rememberMe: formData.get("rememberMe") === "RememberMe",
            acceptedCookiesPolicy: "",
            organizationIndividualInvitationHash: "",
            organizationPublicInvitationLinkHash: ""
        };

        // Cookie policy           
        const cookiesUrl = process.env.REACT_APP_LX_API_URL + "api/cookies-policy/accept";
        var cookiesAcceptationLevel = {
            AcceptedCookieLevel: 1
        };
        try {
            await axios.post(cookiesUrl, cookiesAcceptationLevel, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                },                
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            setLoginError(ResourceManager.getValue("loginFailed"));
        }

        //Login Lx
        const lxUrl = process.env.REACT_APP_LX_API_URL + "api/users/login";
        try {
            var responseLX = await axios.post(lxUrl, loginUserCredentials, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                },                
            })
        }
        catch (error) {
            console.log(error);
            setAuthenticating(false);
            setLoginError(ResourceManager.getValue("loginFailed"));
        } 

        if (responseLX) {
        
            let infoUser = responseLX.data;
            if (infoUser.loginInformation && infoUser.loginInformation.user) {
                var userProfile = await getUserProfile(infoUser.loginInformation.user.id);
                var userOrganizations = await getUserOrganizations(infoUser.loginInformation.user.id);
                var userThumbnail = await getUserThumnail(infoUser.loginInformation.user.id);

                let infosLxWithTokens = {
                    firstName: infoUser.loginInformation.user.firstName,
                    lastName: infoUser.loginInformation.user.lastName,
                    id: infoUser.loginInformation.user.id,
                    email: userProfile.email,
                    organizationIds: userOrganizations,
                    currentOrganizationId: userProfile.organizationId,
                    bmcToken: "",
                    profilePictureThumbnailJpegBase64: userThumbnail
                }
                loginBmc(infosLxWithTokens);
            }
            else {
                setAuthenticating(false);
                setLoginError(ResourceManager.getValue("loginFailed"));
            }
        }               
    
    }

    function onClickLoginWithFestoSSO() {
        setAuthenticating(true);

        let openIdConfiguration = OpenIdUtils.getFestoOpenIdConfiguration();
        let authenticationData = OpenIdUtils.getAuthenticationData(openIdConfiguration, ResourceManager.getLanguage());

        OpenIdUtils.setCodeVerifier(authenticationData.codeVerifier);
        OpenIdUtils.setClientId(openIdConfiguration.clientId);

        window.location.href = authenticationData.authenticationUrl
    }

    function KeyDown(e){
        if (e.key === "Enter") {
            if (ssoAvailable) {
                onClickLoginWithFestoSSO();
            }
            else
            {
                submitLogIn();
            }
        }
    }

    function handleResetPasswordModalOpen() {
        setResetPasswordModalOpen(true);
    }

    function handleResetPasswordModalClose() {
        setResetPasswordModalOpen(false);
    }

    async function UpdateLicense(profilesInfos) {
        const url = process.env.REACT_APP_LX_API_URL + "api/bmcsim/update-license";

        let updateLicenseInfo = {
            CurrentOrganizationId: profilesInfos.CurrentOrganizationId,
            OrganizationIds: profilesInfos.OrganizationIds,
        }

        var response;
        try {
            response = await axios.post(url, updateLicenseInfo, {
                withCredentials: true,
                headers: {
                    "Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
                }
            })
        }
        catch (error) {
            console.log(error);

            if (error.response.data) {
            }

            if (response) {
            }
        }
    }
    const handleEmailChange = (e) => {
        setSsoAvailable(isFestoEmail(e.target.value));
    };

    return (
        <ResetPasswordContext.Provider value={{ resetPasswordModalOpen, handleResetPasswordModalClose }}>
            <div className="BMC-Sim">
                <header className="BMC-Header">
                    <Header />
                </header>
                {CookiesPolicyConsentModal(cookiesPolicyModalOpen, handleCookiesPolicyModalOpen, handleCookiesPolicyModalClose)}
                <ResetPasswordModal />
                <div className="background-image">
                    <ProgressiveImg
                        src={bmcPicture4}
                        placeholderSrc={bmcPlaceholder4}
                        alt="background"
                        height="auto"
                        width="100%"
                    />
                </div>
                <div className="separator" />
                <div className="Login-MainContent">
                    <div style={{ margin: "0 auto", width: "87.5%", padding: "0 16px" }}>
                        <div className={`loginContent`}>
                            <header className="loginHeader">
                                {ResourceManager.getValue("logInTitle")}
                            </header>
                            <br />
                            <form id="logInForm">                                                             
                                {!ssoAvailable ? 
                                    <> 
                                        <input
                                            style={{ width: "100%" }}
                                            type="username"
                                            id="username"
                                            placeholder={ResourceManager.getValue("username")}
                                            name="username"
                                            required={true}
                                            onChange={handleEmailChange}
                                        />   
                                        <br />
                                        <br />
                                        <input 
                                            style={{ width: "100%" }}
                                            type="password"
                                            id="password"
                                            placeholder={ResourceManager.getValue("password")}
                                            name="password"
                                            required={true}
                                            onKeyDown={(e) => KeyDown(e)}
                                            onChange={e => setPasswordInputEmpty(e.target.value ? false : true)}
                                        />
                                        <br />
                                        <br />
                                        <br />
                                        <button
                                            style={{ width: "100%" }}
                                            className={'submitButton rightAlign' + (authenticating ? ' disabled-button' : '')}
                                            type="button"
                                            onClick={submitLogIn}
                                            disabled={authenticating}>
                                            {ResourceManager.getValue("logInTitle")}
                                        </button>
                                    </>
                                    : 
                                    <>      
                                        <input
                                            style={{ width: "100%" }}
                                            type="username"
                                            id="username"
                                            placeholder={ResourceManager.getValue("username")}
                                            name="username"
                                            required={true}
                                            onKeyDown={(e) => KeyDown(e)}
                                            onChange={handleEmailChange}
                                        />   
                                        <br />
                                        <br />
                                        <br />
                                        <button
                                            style={{ width: "100%" }}
                                            className={'submitButton rightAlign' + (authenticating ? ' disabled-button' : '')}
                                            type="button"
                                            onClick={onClickLoginWithFestoSSO}
                                            disabled={authenticating}>
                                            {ResourceManager.getValue("loginSSO")}
                                        </button>
                                    </>
                                }
                                                                
                                <br />
                                {loginError ? <p className="errorText">{ResourceManager.getValue("loginFailed")}</p> : <br />}
                            </form>
                            <a className="login-option-link" href={process.env.REACT_APP_LX_API_URL + ResourceManager.getLanguage() + "/sign-up?redirect=" + encodeURIComponent(window.location.origin + "/login")}
                                onMouseOver={e => e.currentTarget.style.color = 'var(--fwe-hero-dark)'} onMouseOut={e => e.currentTarget.style.color = 'var(--fwe-hero)'}>
                                {ResourceManager.getValue("notRegistered")}
                            </a>
                            <button className="login-option-link" onClick={() => handleResetPasswordModalOpen()}
                                onMouseOver={e => e.currentTarget.style.color = 'var(--fwe-hero-dark)'} onMouseOut={e => e.currentTarget.style.color = 'var(--fwe-hero)'}>
                                {ResourceManager.getValue("forgotPassword")}
                            </button>
                        </div>
                    </div>
                </div>
                <footer className="BMC-Footer">
                    <Footer />
                </footer>
            </div>
        </ResetPasswordContext.Provider>
    );
}