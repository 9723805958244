import React, { useState, useContext, useEffect } from 'react';
import ResetPasswordContext from '../../contexts/resetPassword/withResetPasswordContext';
import './ResetPasswordModal.css';
import { ResourceManager } from '../../index';
import Modal from '../../components/modal/Modal';
import axios from "axios";
import { isEmailFormatValid, isFestoEmail } from '../../utility/emailValidationUtils';

const ResetPasswordModal = () => {
	const { resetPasswordModalOpen, handleResetPasswordModalClose } = useContext(ResetPasswordContext);
	const [resetError, setResetError] = useState("");
	const [resetBtnDisabled, setResetBtnDisabled] = useState(false);
	const [resetSuccessOutput, setResetSuccessOutput] = useState("");
	const [isMounted, setIsMounted] = useState(false);

	// G�re l'�tat de montage du composant.
	// Utilis� pour �viter les mises � jour de l'�tat sur un composant d�mont�,
	// ce qui peut entra�ner des fuites de m�moire.
	useEffect(() => {
		setIsMounted(true);

		return () => {
			setIsMounted(false);
		};
	}, []);

	const onClickReset = async (event) => {
		event.preventDefault();
		setResetBtnDisabled(true);
		const formElement = document.getElementById("resetForm");
		const formData = new FormData(formElement);

		let isEmailValidOutput = validateEmail(formData.get("email"));
		if (isEmailValidOutput) {
			setResetError(isEmailValidOutput);
			setResetSuccessOutput("");
			setResetBtnDisabled(false);
		}
		else {
			let resetPasswordInfo = {
				email: formData.get("email"),
			};

			const url = process.env.REACT_APP_LX_API_URL + "api/users/send-reset-password-email";
			var response;
			try {
				response = await axios.post(url, resetPasswordInfo, {
					withCredentials: true,
					headers: {
						"Content-Type": "application/json", "Access-Control-Allow-Origin": "*",
					}
				})
			}
			catch (error) {
				if (isMounted) {
					console.log(error);
					setResetBtnDisabled(false);
					setResetSuccessOutput("");
					setResetError(ResourceManager.getValue("passwordResetFailed"));
				}
			}
			if (response) {
				if (isMounted) {
					if (response.status === 200) {
						setResetError("");
						setResetSuccessOutput(ResourceManager.getValue("changePassword"));
					} else {
						setResetBtnDisabled(false);
						setResetSuccessOutput("");
						setResetError(ResourceManager.getValue("passwordResetFailed"));
					}
				}
			}
		}
	};

	function validateEmail(email) {
		let lowerCaseEmail = email;
		if (!isEmailFormatValid(lowerCaseEmail)) {
			return ResourceManager.getValue("enterValidEmailAdress");
		}
		if (isFestoEmail(lowerCaseEmail)) {
			return ResourceManager.getValue("contactFestoAdmin");
		}
		return "";
	}

	function getResetPasswordContent() {
		return (
			<>
				<div className="problems-text">
					{ResourceManager.getValue("problemsLogIn")}
				</div>
				{resetSuccessOutput ?
					<>
						<div className="reset-password-text">
							{ResourceManager.getValue("checkEmail")}
						</div>
						<div className="reset-password-output-box">
							<p>{resetSuccessOutput}</p>	
						</div>
						<div className="button-container">
							<span style={{ flexGrow: "100" }} />
							<button
								className={'modal-btn'}
								onClick={() => handleResetPasswordModalClose()}
							>
								{ResourceManager.getValue("close")}
							</button>
						</div>
					</>
					:
					<>
						<div className="reset-password-text">
							{ResourceManager.getValue("resetYourPassword")}
						</div>
						<form id="resetForm" onSubmit={onClickReset}>
							<input style={{ width: "100%" }} type="text" id="email" placeholder={ResourceManager.getValue("emailAdress")} name="email" required={true} />
							<div className="reset-password-error-box">
								<p className="error-text">{resetError}</p>
							</div>
							<div className="button-container">
								<span style={{ flexGrow: "100" }} />
								<button
									type="submit"
									className={'modal-btn' + (resetBtnDisabled ? ' disabled-button' : '')}
								>
									{ResourceManager.getValue("resetPassword")}
								</button>
							</div>
						</form>
					</>
				}
			</>
		);
	}

	return (
		< Modal isOpen={resetPasswordModalOpen} onClose={handleResetPasswordModalClose} showCloseButton={true} >
			{getResetPasswordContent()}
		</Modal >
	);
};

export default ResetPasswordModal;